.gm-style .gm-style-iw-c {
  padding: 8px !important;
  padding-bottom: 12px !important;
  border-radius: 0;
}
.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}
button.gm-ui-hover-effect {
  display: none !important;
}
